// MAIN.JS
jQuery(function($) {

	/**********************************************/
	$layerslider = $('#layerslider');
	$layerslidermobile = $('#layerslider-mobile');

	$layerslider.layerSlider({
		responsive: true,
		pauseOnHover: false,
		navPrevNext: true,
		navStartStop: false,
		hoverPrevNext: true,
		keybNav: true,
		showCircleTimer: false,
		thumbnailNavigation: 'disabled'
	});

	$layerslidermobile.layerSlider({
		responsive: true,
		navButtons: false,
		pauseOnHover: false,
		navPrevNext: false,
		navStartStop: false,
		hoverPrevNext: false,
		keybNav: false,
		showCircleTimer: false,
		thumbnailNavigation: 'disabled'
	});

	$(".slideshow-next").click(function() {
		$layerslider.layerSlider('next');
		$layerslidermobile.layerSlider('next');
	});
	$(".slideshow-prev").click(function() {
		$layerslider.layerSlider('prev');
		$layerslidermobile.layerSlider('prev');
	});

	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});

		$('.image-group-popup').each(function() {
			$(this).magnificPopup({
				delegate: 'a',
				type: 'image',
				gallery: {
					enabled: true
				}
			});
		});

		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').each(function() {
			$(this).magnificPopup({
				delegate: 'a',
				type: 'iframe',
				gallery: {
					enabled: true
				}
			});
		});

	}


	/**********************************************/

	// MENU
	var menu = "#mobile-main-menu";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

		/**********************************************/

			// MASONRY
			if ($.fn.masonry) {
				$(window).load(function(){
					$('.grid').masonry({
						itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
						columnWidth: '.grid-sizer',
						percentPosition: true
					});
				});
			}

	/**********************************************/

	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel();
	}

	/**********************************************/

	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});
	}
/**************************************************/

	$(".popup").click(function (e) {
		$(".popup").fadeOut();
	});
});
